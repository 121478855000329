import React, { useMemo } from 'react'
import classNamesBind from 'classnames/bind'
import styles from './CurveChart.module.scss'

import { CurveChartProps } from './CurveChart.d'
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line } from 'recharts'
import convert from 'color-convert'

const cx = classNamesBind.bind(styles)

const CurveChart: React.FC<CurveChartProps.Props> = props => {
	const { className, colors } = props

	const data = useMemo(() => {
		return colors.map(c =>
			c ? { h: c[0] || 0, s: c[1] || 0, v: c[2] || 0 } : { h: 0, s: 0, v: 0 },
		)
	}, [colors])

	return (
		<div className={cx('wrapper', className)}>
			<LineChart
				width={500}
				height={300}
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid />
				<XAxis type='number' domain={[0, 100]} dataKey='s' />
				<YAxis type='number' domain={[0, 100]} />
				<Tooltip />
				<Line
					type='monotone'
					dataKey='v'
					isAnimationActive={false}
					stroke={
						'#' +
						convert.hsv.hex([
							data[Math.round(data.length / 2)].h,
							data[Math.round(data.length / 2)].s,
							data[Math.round(data.length / 2)].v,
						])
					}
				/>
			</LineChart>
		</div>
	)
}

// const mapStateToProps = (state: IAppState): CurveChartProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	CurveChartProps.Dispatch,
// 	CurveChartProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(CurveChart);

export default CurveChart
