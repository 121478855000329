import React, { useState } from 'react'
import classNamesBind from 'classnames/bind'
import styles from './Features.module.scss'

import { FeaturesProps } from './Features.d'
import { CardHeader } from 'components/common'
import { Slider, Switch } from 'components/UI'

const cx = classNamesBind.bind(styles)

const Features: React.FC<FeaturesProps.Props> = props => {
	const { className } = props

	const [wcag, setWcag] = useState(true) // TODO replace with redux
	const [blueOnGray, setBlueOnGray] = useState(50)

	return (
		<div className={cx('wrapper', className)}>
			<CardHeader title='Features' action='HelpCircle' actionType='secondary' />
			<div className={cx('content')}>
				<div className={cx('wcag')}>
					<div className={cx('wcagText')}>WCAG Compliance</div>
					<Switch value={wcag} onChange={setWcag} />
				</div>
				<div className={cx('blueSlider')}>
					<div className={cx('blueText')}>Blue on gray</div>
					<Slider value={blueOnGray} onChange={setBlueOnGray} />
				</div>
			</div>
		</div>
	)
}

// const mapStateToProps = (state: IAppState): FeaturesProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	FeaturesProps.Dispatch,
// 	FeaturesProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(Features);

export default Features
