import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './Graph.module.scss'

import { GraphProps } from './Graph.d'
import { CardHeader } from 'components/common'
import { Input, Slider } from 'components/UI'

const cx = classNamesBind.bind(styles)

const Graph: React.FC<GraphProps.Props> = props => {
	const { className } = props

	return (
		<div className={cx('wrapper', className)}>
			<CardHeader title='Graph' action='HelpCircle' actionType='secondary' />
			<div className={cx('content')}>
				<div className={cx('param')}>
					<div className={cx('name')}>X-axis</div>
					<Slider value={10} className={cx('slider')} onChange={() => {}} />
					<Input value={'100'} className={cx('input')} onChange={() => {}} />
				</div>
				<div className={cx('param')}>
					<div className={cx('name')}>Y-axis</div>
					<Slider value={10} className={cx('slider')} onChange={() => {}} />
					<Input value={'100'} className={cx('input')} onChange={() => {}} />
				</div>
				<div className={cx('param')}>
					<div className={cx('name')}>Angle</div>
					<Slider value={10} className={cx('slider')} onChange={() => {}} />
					<Input value={'100'} className={cx('input')} onChange={() => {}} />
				</div>
			</div>
		</div>
	)
}

// const mapStateToProps = (state: IAppState): GraphProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	GraphProps.Dispatch,
// 	GraphProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(Graph);

export default Graph
