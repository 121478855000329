import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './Switch.module.scss'

import { SwitchProps } from './Switch.d'

const cx = classNamesBind.bind(styles)

const Switch: React.FC<SwitchProps.Props> = props => {
	const { className, value, onChange } = props

	const _onChange = () => onChange(!value)

	return (
		<div className={cx('wrapper', className, { active: value })} onClick={_onChange}>
			<div className={cx('knob')} />
		</div>
	)
}

// const mapStateToProps = (state: IAppState): SwitchProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	SwitchProps.Dispatch,
// 	SwitchProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(Switch);

export default Switch
