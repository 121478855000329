import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './LinkButton.module.scss'

import { LinkButtonProps } from './LinkButton.d'

const cx = classNamesBind.bind(styles)

const LinkButton: React.FC<LinkButtonProps.Props> = props => {
	const { className, children, onClick, disabled } = props

	return (
		<div className={cx('wrapper', className, { disabled })} onClick={onClick}>
			{children}
		</div>
	)
}

// const mapStateToProps = (state: IAppState): LinkButtonProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	LinkButtonProps.Dispatch,
// 	LinkButtonProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(LinkButton);

export default LinkButton
