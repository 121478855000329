import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './ColorItem.module.scss'

import { ColorItemProps } from './ColorItem.d'
import { ActionButton, Icon } from 'components/UI'

const cx = classNamesBind.bind(styles)

const ColorItem: React.FC<ColorItemProps.Props> = props => {
	const { className, color, onChange, onDelete } = props

	const { id, hex, name } = color
	const _onDelete = () => onDelete(id)

	// eslint-disable-next-line  @typescript-eslint/no-unused-vars
	const onNameChange = (name: string) => {
		// TODO
		onChange({ ...color, name })
	}

	// eslint-disable-next-line  @typescript-eslint/no-unused-vars
	const onColorChange = (hex: string) => {
		// TODO
		onChange({ ...color, hex })
	}

	const _hex = hex.replace('#', '')

	return (
		<div className={cx('wrapper', className)}>
			<div className={cx('color')} style={{ backgroundColor: hex }}>
				<Icon icon='Sliders' size='16' className={cx('editColorIcon')} />
			</div>
			<div className={cx('info')}>
				<div className={cx('name')}>{name}</div>
				<div className={cx('hex')}>{_hex}</div>
			</div>
			<ActionButton action='Trash' type='secondary' onClick={_onDelete} className={cx('trash')} />
		</div>
	)
}

// const mapStateToProps = (state: IAppState): ColorItemProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	ColorItemProps.Dispatch,
// 	ColorItemProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(ColorItem);

export default ColorItem
