import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './Button.module.scss'

import { ButtonProps } from './Button.d'

const cx = classNamesBind.bind(styles)

const Button: React.FC<ButtonProps.Props> = props => {
	const { className, children, onClick, disabled } = props

	return (
		<div className={cx('wrapper', className, { disabled })} onClick={onClick}>
			{children}
		</div>
	)
}

export default Button
