import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './Input.module.scss'

import { InputProps } from './Input.d'

const cx = classNamesBind.bind(styles)

const Input: React.FC<InputProps.Props> = props => {
	const { className, value, onChange } = props

	const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)

	return <input value={value} onChange={_onChange} className={cx('input', className)} />
}

// const mapStateToProps = (state: IAppState): InputProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	InputProps.Dispatch,
// 	InputProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(Input);

export default Input
