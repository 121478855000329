import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './Color.module.scss'

import { ColorProps } from './Color.d'
import convert from 'color-convert'

const cx = classNamesBind.bind(styles)

const Color: React.FC<ColorProps.Props> = props => {
	const { className, children, size = 'normal', active, onClick, color } = props
	const light = convert.hex.gray(color)[0] > 50

	return (
		<div
			onClick={onClick}
			className={cx('wrapper', `size-${size}`, className, {
				clickable: !!onClick,
				active: !!active,
			})}
			style={{
				backgroundColor: color,
				color: light ? 'black' : 'white',
			}}
		>
			{children}
			{light}
		</div>
	)
}

// const mapStateToProps = (state: IAppState): ColorProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	ColorProps.Dispatch,
// 	ColorProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(Color);

export default Color
