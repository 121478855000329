import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './Slider.module.scss'
import { Slider as MUSlider } from '@material-ui/core'
import { SliderProps } from './Slider.d'

const cx = classNamesBind.bind(styles)

const Slider: React.FC<SliderProps.Props> = props => {
	const { className, onChange, value } = props

	const _onChange = (_e, value: number | number[]) =>
		onChange(Array.isArray(value) ? value[0] : value)

	return <MUSlider value={value} onChange={_onChange} className={cx('slider', className)} />
}

// const mapStateToProps = (state: IAppState): SliderProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	SliderProps.Dispatch,
// 	SliderProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(Slider);

export default Slider
