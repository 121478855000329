import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './Colors.module.scss'

import { ColorsProps } from './Colors.d'
import { CardHeader, ColorItem } from 'components/common'

const cx = classNamesBind.bind(styles)

const Colors: React.FC<ColorsProps.Props> = props => {
	const { className } = props

	const colors = [
		{
			id: '1',
			name: 'red',
			hex: '#ff0000',
		},
		{
			id: '2',
			name: 'purple',
			hex: '#6767E6',
		},
	]

	return (
		<div className={cx('wrapper', className)}>
			<CardHeader title='Colors' action='Plus' />
			<div className={cx('colors')}>
				{colors.map(c => (
					<ColorItem key={c.id} color={c} onChange={() => {}} onDelete={() => {}} />
				))}
			</div>
		</div>
	)
}

// const mapStateToProps = (state: IAppState): ColorsProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	ColorsProps.Dispatch,
// 	ColorsProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(Colors);

export default Colors
