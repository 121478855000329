import React, { useState } from 'react'
import classNamesBind from 'classnames/bind'
import styles from './Header.module.scss'
import SistemaLogo from 'assets/images/sistema-logo.png'
import { HeaderProps } from './Header.d'
import { ActionButton, Button, LinkButton, ThemeSwitch } from 'components/UI'

const cx = classNamesBind.bind(styles)

const Header: React.FC<HeaderProps.Props> = props => {
	const { className } = props

	const [theme, setTheme] = useState(false)

	return (
		<div className={cx('wrapper', className)}>
			<div className={cx('block')}>
				<img src={SistemaLogo} className={cx('logo')} alt='sistema logo' height={46} />
				<div className={cx('nameWrapper')}>
					<div className={cx('name')}>Palette name with many words and dicks</div>
					<div className={cx('lastChange')}>Last change: 30 secs ago</div>
				</div>
				<div className={cx('actions')}>
					<ActionButton action='Save' color='black' className={cx('action')} />
					<ActionButton action='Undo' color='black' className={cx('action', 'undo')} />
					<ActionButton action='Redo' color='black' className={cx('action', 'redo')} />
					<ActionButton action='Share' color='black' className={cx('action')} />
				</div>
			</div>

			<div className={cx('block')}>
				<div className={cx('block', 'theme')}>
					<div className={cx('themeName')}>Light</div>
					<ThemeSwitch value={theme} onChange={setTheme} />
					<div className={cx('themeName')}>Dark</div>
				</div>
				<div className={cx('block')}>
					<LinkButton>About</LinkButton>
					<LinkButton>Contact</LinkButton>
					<LinkButton>Github</LinkButton>
				</div>
				<div className={cx('block')}>
					<LinkButton>Login</LinkButton>
					<Button className={cx('register')}>Register</Button>
				</div>
			</div>
		</div>
	)
}

// const mapStateToProps = (state: IAppState): HeaderProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	HeaderProps.Dispatch,
// 	HeaderProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(Header);

export default Header
