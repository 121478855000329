import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './CardHeader.module.scss'

import { CardHeaderProps } from './CardHeader.d'
import { ActionButton } from 'components/UI'

const cx = classNamesBind.bind(styles)

const CardHeader: React.FC<CardHeaderProps.Props> = props => {
	const { className, title, action, actionColor, actionType } = props

	return (
		<div className={cx('wrapper', className)}>
			<div className={cx('title')}>{title}</div>
			<ActionButton
				action={action}
				color={actionColor}
				type={actionType}
				className={cx('action')}
			/>
		</div>
	)
}

// const mapStateToProps = (state: IAppState): CardHeaderProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	CardHeaderProps.Dispatch,
// 	CardHeaderProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(CardHeader);

export default CardHeader
