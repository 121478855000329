import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './ActionButton.module.scss'

import { ActionButtonProps } from './ActionButton.d'
import { Icon } from '../Icon'

const cx = classNamesBind.bind(styles)

const ActionButton: React.FC<ActionButtonProps.Props> = props => {
	const {
		className,
		type = 'primary',
		action: icon,
		color = type === 'primary' ? 'accent' : 'gray',
		size = '24',
		onClick,
	} = props

	return (
		<div className={cx('wrapper', className, `type-${type}`, `color-${color}`)} onClick={onClick}>
			<Icon size={size} icon={icon} className={cx('icon')} />
		</div>
	)
}

// const mapStateToProps = (state: IAppState): ActionButtonProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	ActionButtonProps.Dispatch,
// 	ActionButtonProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(ActionButton);

export default ActionButton
