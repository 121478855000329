import React, { useMemo } from 'react'
import classNamesBind from 'classnames/bind'
import styles from './ColorsChart.module.scss'

import { ColorsChartProps } from './ColorsChart.d'
import { CartesianGrid, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts'
import convert from 'color-convert'
const cx = classNamesBind.bind(styles)

const ColorsChart: React.FC<ColorsChartProps.Props> = props => {
	const { className, colors } = props

	const lines = useMemo(() => {
		return colors.map(l =>
			l.map(([h, s, v]) => ({
				h,
				s,
				v,
			})),
		)
	}, [colors])

	return (
		<div className={cx('wrapper', className)}>
			<ScatterChart
				width={500}
				height={300}
				margin={{
					right: 40,
					left: 40,
					top: 20,
					bottom: 20,
				}}
			>
				<CartesianGrid />
				<XAxis domain={[0, 100]} type='number' dataKey='s' name='s' />
				<YAxis domain={[0, 100]} type='number' dataKey='v' name='v' />
				<Tooltip cursor={{ strokeDasharray: '3 3' }} />
				{lines.map(l => (
					<Scatter
						isAnimationActive={false}
						data={l}
						fill={
							'#' +
							convert.hsv.hex([
								l[Math.round(l.length / 2)]?.h || 0,
								l[Math.round(l.length / 2)]?.s || 0,
								l[Math.round(l.length / 2)]?.v || 0,
							])
						}
					/>
				))}
			</ScatterChart>
		</div>
	)
}

// const mapStateToProps = (state: IAppState): ColorsChartProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	ColorsChartProps.Dispatch,
// 	ColorsChartProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(ColorsChart);

export default ColorsChart
