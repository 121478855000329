import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './PaletteItem.module.scss'

import { PaletteItemProps } from './PaletteItem.d'
import { is_light } from 'utils'

const cx = classNamesBind.bind(styles)

const PaletteItem: React.FC<PaletteItemProps.Props> = props => {
	const { className, color, index } = props

	const _color = color.replace('#', '').toUpperCase()
	const wcag = 'AAA'

	const onClick = () => {
		// TODO copy to clipboard
	}

	return (
		<div
			style={{ backgroundColor: color }}
			className={cx('wrapper', className, {
				light: is_light(color),
			})}
			onClick={onClick}
		>
			<div className={cx('index')}>{index}</div>
			<div className={cx('wcag')}>{wcag}</div>
			<div className={cx('color')}>{_color}</div>
		</div>
	)
}

// const mapStateToProps = (state: IAppState): PaletteItemProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	PaletteItemProps.Dispatch,
// 	PaletteItemProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(PaletteItem);

export default PaletteItem
