import React, { useState } from 'react'
import classNamesBind from 'classnames/bind'
import styles from './Palette.module.scss'
import { PaletteProps } from './Palette.d'
import { PaletteItem, RefPaletteItem } from 'components/UI'
import { b_to_hex } from 'utils'
const cx = classNamesBind.bind(styles)

const references = [95, 86, 77, 68, 59, 50, 41, 32, 23, 14]

const colors = [
	{ id: '1', name: 'Red', hex: '#ff0000' },
	{ id: '2', name: 'Blue', hex: '#ff0000' },
	{ id: '3', name: 'Green', hex: '#ff0000' },
	{ id: '4', name: 'Olegoviy', hex: '#ff0000' },
]

const Palette: React.FC<PaletteProps.Props> = props => {
	const { className } = props

	const [active, setActive] = useState<string>('1')

	return (
		<>
			<svg height='0'>
				<defs>
					<clipPath id='palette-name-clip'>
						<path d='M24 20C12.5 19.9935 12.5 -0.00366356 1 -4.50475e-07L0 0V21H24V20Z' />
					</clipPath>
				</defs>
			</svg>

			<div className={cx('wrapper', className)}>
				<div className={cx('references', 'row')}>
					{references.map((r, i) => (
						<RefPaletteItem className={cx('item')} key={r} index={(i + 1) * 10} hex={b_to_hex(r)} />
					))}
				</div>
				{colors.map(color => (
					<div
						key={color.id}
						className={cx('colors', 'block', { active: color.id === active })}
						onClick={() => setActive(color.id)}
					>
						<div className={cx('nameWrapper')}>
							<div className={cx('name')}>{color.name}</div>
						</div>
						<div className={cx('row')}>
							{references.map((r, i) => (
								<PaletteItem
									className={cx('item')}
									key={r}
									index={(i + 1) * 10}
									color={b_to_hex(r)}
								/>
							))}
						</div>
					</div>
				))}
			</div>
		</>
	)
}

// const mapStateToProps = (state: IAppState): PaletteProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	PaletteProps.Dispatch,
// 	PaletteProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(Palette);

export default Palette
