import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './ThemeSwitch.module.scss'

import { ThemeSwitchProps } from './ThemeSwitch.d'
import { Icon } from '../Icon'

const cx = classNamesBind.bind(styles)

const ThemeSwitch: React.FC<ThemeSwitchProps.Props> = props => {
	const { className, value, onChange } = props

	const _onChange = () => onChange(!value)

	return (
		<div className={cx('wrapper', className, { active: value })} onClick={_onChange}>
			<div className={cx('knob')}></div>
			<Icon icon='Sun' className={cx('light')} />
			<Icon icon='Moon' className={cx('dark')} />
		</div>
	)
}

// const mapStateToProps = (state: IAppState): ThemeSwitchProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	ThemeSwitchProps.Dispatch,
// 	ThemeSwitchProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(ThemeSwitch);

export default ThemeSwitch
