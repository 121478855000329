import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './RefPaletteItem.module.scss'
import { RefPaletteItemProps } from './RefPaletteItem.d'
import { hex_to_b, is_light } from 'utils'

const cx = classNamesBind.bind(styles)

const RefPaletteItem: React.FC<RefPaletteItemProps.Props> = props => {
	const { className, hex, index } = props

	const brightness = hex_to_b(hex)

	return (
		<div
			className={cx('wrapper', className, {
				light: is_light(hex),
			})}
			style={{ backgroundColor: hex }}
		>
			<div className={cx('index')}>{index}</div>
			<div className={cx('brightness')}>B: {brightness}</div>
		</div>
	)
}

// const mapStateToProps = (state: IAppState): RefPaletteItemProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	RefPaletteItemProps.Dispatch,
// 	RefPaletteItemProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(RefPaletteItem);

export default RefPaletteItem
