import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './App.module.scss'

import { AppProps } from './App.d'
import { Header } from 'components/common'
import { Colors, Features, Graph, Palette } from 'components/containers'

const cx = classNamesBind.bind(styles)

const App: React.FC<AppProps.Props> = props => {
	const { className } = props

	return (
		<div className={cx('wrapper', className)}>
			<Header className={cx('header')} />
			<div className={cx('content')}>
				<div className={cx('col', 'left')}>
					<Features className={cx('card', 'features')} />
					<Colors className={cx('card', 'colors')} />
				</div>
				<div className={cx('col', 'center')}>
					<Palette className={cx('card', 'palette')} />
				</div>
				<div className={cx('col', 'right')}>
					<Graph className={cx('card', 'graph')} />
				</div>
			</div>
		</div>
	)
}

// const mapStateToProps = (state: IAppState): AppProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	AppProps.Dispatch,
// 	AppProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(App);

export default App
